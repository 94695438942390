import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./styles.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Store";
import UnauthorizedModal from "./Components/Shared/Modal/UnauthorizedModal";
import AppRoutes from "./Routes/AppRoutes";
import "./tailwind.css";
import ThemeProvider from "./ThemeProvider";

export default function App() {
  useEffect(() => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      navigator.serviceWorker
        .register("/sw.js")
        .then((swReg) => {
          console.log("Service Worker Registered", swReg);
        })
        .catch((error) => {
          console.error("Service Worker Error", error);
        });
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <BrowserRouter>
            <AppRoutes />
            <UnauthorizedModal />
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}
